<template>
  <div class="bg-white creative">
    <el-tabs
      v-model="current"
      type="card"
      :before-leave="beforeLeave"
      editable
      @edit="handleTabsEdit"
      @tab-click="handleClick"
    >
      <el-tab-pane
        v-for="(creative, index) in value"
        :key="index"
        :name="index + ''"
      >
        <div slot="label">
          {{ creativeName(creative, index) }}
          <!-- <i class="el-icon-circle-close ml-2 text-zinc-800 hover:text-primary"></i> -->
        </div>
        <div class="m-6">
          <!-- <el-form
            label-width="100px"
            :model="creative"
            :rules="rules"
            ref="form"
          >
            <el-form-item label="品牌名称">
              <el-input
                disabled
                :value="creative.brandName"
              ></el-input>
            </el-form-item>
            <el-form-item label="广告样式">
              <el-tabs
                type="border-card"
                v-model="creative.type"
                @tab-click="(tab) => selectCreativeType(tab, creative)"
              >
                <el-tab-pane
                  label="图像或视频"
                  name="WEB_VIEW"
                >
                </el-tab-pane>
                <el-tab-pane
                  label="故事广告"
                  name="COMPOSITE"
                >
                </el-tab-pane>
                <el-tab-pane
                  label="精选广告"
                  name="COLLECTION"
                >
                </el-tab-pane>
                <div>
                  <el-form-item
                    class="!mb-6"
                    prop="infos"
                  >
                    <upload
                      action="#"
                      accept=".jpg,.png,.mp4,.mov"
                      multiple
                      :show-file-list="false"
                      :API="API"
                      :value.sync="creative.infos"
                      :limit="10"
                      list-type="picture-card"
                      :selectLimit="selectMediaLimit"
                      @change="(list) => (creative.type == 'COMPOSITE' ? null : onChangeImg(index, list))"
                      :selectImgIndex.sync="creative.type == 'COMPOSITE' ? null : creative.selectImgIndex"
                      class="mb-6 uploadSnapchat"
                    >
                      <div
                        class="leading-4 text-xs border w-full h-full flex flex-col justify-center items-center rounded-md"
                      >
                        <el-button type="primary">点击上传</el-button>
                        <div class="text-gray-700 leading-4 mt-1">
                          <div>尺寸：1080 x 1920</div>
                          <div>视频必须使用 H.264 编码</div>
                          <div>且时长不得超过 180 秒</div>
                          <div>图像格式必须为PNG或JPEG</div>
                        </div>
                      </div>
                    </upload>
                    <el-input
                      v-if="creative.infos.length > 0"
                      placeholder="请输入标题"
                      maxlength="34"
                      show-word-limit
                      v-model="creative.infos[creative.selectImgIndex || 0].headline"
                      @change="(value) => onChangeHeadline(value, index, creative)"
                    ></el-input>
                  </el-form-item>
                </div>
              </el-tabs>
            </el-form-item>
            <span v-if="creative.type == 'COLLECTION'">
              <el-form
                ref="form"
                :model="creative.additionalCreativeInfo"
                label-width="100px"
                :rules="addRules"
              >
                <el-form-item
                  label="广告缩略图"
                  prop="collectionMediaUrl"
                >
                  <div class="flex mr-2">
                    <div
                      v-for="(img, index) in creative.additionalCreativeInfo.collectionMediaUrl"
                      class="flex mr-4"
                    >
                      <div class="relative w-24 h-24">
                        <el-image
                          class="w-24 h-24"
                          :src="img"
                          fit="contain"
                        ></el-image>
                        <i
                          class="el-icon-close absolute -top-1 -right-1 cursor-pointer"
                          @click="creative.additionalCreativeInfo.collectionMediaUrl.splice(index, 1)"
                        ></i>
                      </div>
                    </div>
                    <el-upload
                      v-if="creative.additionalCreativeInfo.collectionMediaUrl.length < 4"
                      action="#"
                      :show-file-list="false"
                      :http-request="(file) => uploadImgHandle(file, 'collectionMediaUrl', creative)"
                      :before-upload="(file) => selectImg(file, 'collectionMediaUrl')"
                    >
                      <el-image
                        class="w-24 h-24"
                        :src="addImgIcon"
                      ></el-image>
                    </el-upload>
                  </div>
                </el-form-item>
              </el-form>
            </span>
            <span v-if="creative.type == 'COMPOSITE'">
              <el-form
                ref="form"
                :model="creative.additionalCreativeInfo"
                :rules="addRules"
                label-width="100px"
              >
                <el-form-item
                  label="投放版位"
                  prop="hasPreview"
                >
                  <el-radio-group
                    v-model="creative.additionalCreativeInfo.hasPreview"
                    @change="(val) => selectHasPreview(val, index, creative)"
                  >
                    <el-radio :label="false">内容之前</el-radio>
                    <el-radio :label="true">发现动态</el-radio>
                  </el-radio-group>
                </el-form-item>
                <span v-if="creative.additionalCreativeInfo.hasPreview == true">
                  <el-form-item
                    label="标题"
                    prop="headline"
                  >
                    <el-input
                      v-model="creative.additionalCreativeInfo.headline"
                      placeholder="请输入标题"
                      disabled
                    ></el-input>
                  </el-form-item>
                  <el-form-item
                    label="背景"
                    prop="backgroundMediaUrl"
                  >
                    <el-upload
                      v-if="!creative.additionalCreativeInfo.backgroundMediaUrl"
                      action="#"
                      :show-file-list="false"
                      :http-request="(file) => uploadImgHandle(file, 'backgroundMediaUrl', creative)"
                      :before-upload="(file) => selectImg(file, 'backgroundMediaUrl')"
                    >
                      <el-button type="text">点击上传</el-button>
                    </el-upload>
                    <div
                      v-else
                      class="relative w-16 h-24"
                    >
                      <el-image
                        class="w-16 h-24"
                        :src="creative.additionalCreativeInfo.backgroundMediaUrl"
                        fit="contain"
                      ></el-image>
                      <i
                        class="el-icon-close absolute -top-1 -right-1 cursor-pointer"
                        @click="creative.additionalCreativeInfo.backgroundMediaUrl = ''"
                      ></i>
                    </div>
                  </el-form-item>
                  <el-form-item
                    label="logo"
                    prop="logoMedia"
                  >
                    <el-upload
                      v-if="!creative.additionalCreativeInfo.logoMedia"
                      action="#"
                      :show-file-list="false"
                      :http-request="(file) => uploadImgHandle(file, 'logoMedia', creative)"
                      :before-upload="(file) => selectImg(file, 'logoMedia')"
                    >
                      <el-button type="text">点击上传</el-button>
                    </el-upload>
                    <div
                      v-else
                      class="relative w-24 h-8"
                    >
                      <el-image
                        class="w-24 h-12 mt-2"
                        :src="creative.additionalCreativeInfo.logoMedia"
                        fit="contain"
                      ></el-image>
                      <i
                        class="el-icon-close absolute -top-1 -right-1 cursor-pointer"
                        @click="creative.additionalCreativeInfo.logoMedia = ''"
                      ></i>
                    </div> </el-form-item
                ></span>
              </el-form>
            </span>
            <el-form-item
              label="行动号召"
              prop="callToAction"
            >
              <el-select v-model="creative.callToAction">
                <el-option
                  v-for="callToAction in enumConstants.callToActions"
                  :key="callToAction.key"
                  :label="callToAction.value"
                  :value="callToAction.key"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item
              label="推广网址"
              prop="webViewUrl"
            >
              <div class="flex">
                <el-input
                  v-model="creative.webViewUrl"
                  disabled
                  class="flex-1"
                ></el-input>
                <a
                  :href="creative.webViewUrl"
                  target="_blank"
                  class="ml-6"
                >
                  <el-button type="text">预览</el-button>
                </a>
              </div>
            </el-form-item>
          </el-form> -->
          <creatives
            ref="creatives"
            :creativeInfo="creative"
            :index="index"
            :enumConstants="enumConstants"
            @setCreative="setCreative"
          ></creatives>
        </div>
      </el-tab-pane>
      <el-tab-pane name="add">
        <span
          slot="label"
          @click.stop="$emit('addCreative')"
          ><i class="el-icon-circle-plus-outline"></i> 添加创意</span
        >
      </el-tab-pane>
    </el-tabs>
    <!-- <img-cut
      :dialogVisible.sync="dialogVisible"
      :imageSrc="imgUrl"
      :cutImgParams="cutImgParams"
      :fileMsg="fileMsg"
      @uploadImg="uploadCutImg"
    ></img-cut> -->
  </div>
</template>
<script>
import { upload } from '@adCommonComponent';
import { uploadSingle, uploadImg } from '@/api/common.js';
import getImageSize from '@adCommonComponent/utils/getImageSize.js';
import getVideoMes from '@adCommonComponent/utils/getVideoMes.js';
import imgCut from '../../../components/imgCut/index.vue';
import creatives from '@/views/snapchat/create/components/creatives/creatives.vue';
const validatorCollMedia = (rule, value, callback) => {
  console.log(value);
  if (!value.length) {
    callback(new Error('请上传缩略图'));
  }
  if (value.length < 2 || value.length > 4) {
    callback(new Error('请上传至少2张至多4张缩略图'));
  }
  callback();
};

export default {
  components: { upload, imgCut, creatives },
  props: {
    value: Array,
    data: Object,
    enumConstants: {},
  },
  data() {
    return {
      API: uploadSingle,
      current: '0',
      selectImgIndex: null,
      addImgIcon: require('@/assets/icon/addImg.png'),
      rules: {
        infos: [
          {
            validator: this.validatorMedia,
            trigger: 'change',
          },
        ],
        callToAction: [
          {
            required: true,
            message: '请选择行动号召',
          },
        ],
      },
      addRules: {
        hasPreview: [{ required: true, message: '请选择投放版位', trigger: 'change' }],
        headline: [{ required: true, message: '请填写标题', trigger: 'change' }],
        backgroundMediaUrl: [{ required: true, message: '请上传背景图', trigger: 'change' }],
        logoMedia: [{ required: true, message: '请上传logo', trigger: 'change' }],
        collectionMediaUrl: [{ validator: validatorCollMedia, required: true }],
      },
      imgUrl: '',
      dialogVisible: false,
      initCutParams: {
        outputType: 'png', //输出图片的格式
        infoTrue: true,
      },
      cutImgParams: {},
      fileMsg: {}, //当前选择图片的信息，存下来便于裁剪之后使用
    };
  },
  methods: {
    beforeLeave(active, oldActive) {
      console.log(active, oldActive);
      if (active == 'add') {
        this.$emit('addCreative');
        return false;
      }
    },
    selectMediaLimit(file) {
      console.log(file);
      return new Promise((relsove, reject) => {
        const raw = file.raw;
        const imgTypes = ['image/png', 'image/jpg', 'image/jpeg'];
        const videoType = ['video/mp4'];
        const fileSize = raw.size / 1024 / 1024;
        // 格式限制
        if (imgTypes.includes(raw.type)) {
          // 图片
          const isLt5MB = fileSize <= 5;
          if (!isLt5MB) {
            return reject('文件限制在5MB以内');
          }

          getImageSize(file.url)
            .then((res) => {
              const { width, height } = res;
              if (width != 1080 || height != 1920) {
                return reject('请选择1080*1920比例的图片');
              }
              relsove();
            })
            .catch((err) => {
              console.log('err', err);
            });
        } else if (videoType.includes(raw.type)) {
          // 视频
          const isLt1GB = fileSize <= 1024;
          if (!isLt1GB) {
            return reject('文件限制在1GB以内');
          }
          getVideoMes(file)
            .then((res) => {
              const { isVideoH264, duration, videoWidth, videoHeight } = res;
              if (videoWidth != 1080 || videoHeight != 1920) {
                return reject('请选择1080*1920比例的视频');
              }
              if (!isVideoH264) {
                return reject('请上传H.264 编码视频');
              }
              if (duration < 3 || duration > 180) {
                return reject('请上传时长在3-180秒中间视频');
              }

              relsove();
            })
            .catch(() => {
              return reject('文件加载失败');
            });
        } else {
          reject('文件格式不正确');
        }
      });
    },
    // 校验素材
    validatorMedia(rule, value, callback) {
      if (!value || !value.length) {
        callback('请上传素材');
      } else if (!value[0].headline) {
        callback(new Error('请填写标题'));
      } else {
        const status = Array.from(new Set(value.map((item) => item.status)));
        if (status.length == 1 && status.includes('success')) {
          callback();
        } else {
          callback(new Error('存在上传中或上传失败的素材'));
        }
      }
    },
    // 对外提供的验证
    validate() {
      let list = [];
      this.errList = [];
      console.log(this.$refs.creatives, 'this.$refs.creatives');

      this.$refs.creatives.map((form, index) => {
        form.$refs.form.clearValidate();
        list.push(form.$refs.form.validate().catch(() => index));
      });
      console.log(list, 'list');

      return new Promise((resolve, reject) => {
        Promise.allSettled(list).then((res) => {
          res.map((item) => {
            console.log(item, 'item');

            if (typeof item.value == 'number') {
              this.errList.push(item.value);
              reject();
            }
          });
          resolve();
        });
      });
    },
    clearValidate() {
      if (!this.$refs.form) return;
      let list = [];
      this.errList = [];
      this.$refs.form.map((form, index) => {
        list.push(form.clearValidate());
      });
      return Promise.all(list);
    },
    setCreative(creative, index) {
      console.log(creative, index, '接收创意');
      this.$set(this.value, index, creative);
    },
    // 改变故事广告版位
    selectHasPreview(val, index, creative) {
      if (val) {
        this.onChangeHeadline(this.value[index].infos[0].headline, index, creative);
      }
    },
    onChangeHeadline(val, index, creative) {
      if (creative.additionalCreativeInfo.hasPreview) {
        creative.additionalCreativeInfo.headline = val;
      }
      if (creative.selectImgIndex === null) {
        this.value[index].infos.map((item) => {
          this.$set(item, 'headline', val || '');
        });
      } else {
        this.value[index].infos.map((item) => {
          if (!item.headline) {
            this.$set(item, 'headline', val || '');
          }
        });
      }
    },
    // onChangeImg(index) {
    //   let isNoStatusSuccess = [];
    //   this.value[index].infos.map((item) => {
    //     if (!item.headline) {
    //       this.$set(item, 'headline', this.value[index].infos[0].headline || '');
    //     }
    //     if (item.status != 'success') {
    //       isNoStatusSuccess.push(item);
    //     }
    //   });
    //   if (isNoStatusSuccess.length == 0) {
    //     this.$refs.form[0].validateField(['infos']);
    //   }
    //   if (this.value[index].selectImgIndex >= this.value[index].infos.length) {
    //     this.value[index].selectImgIndex = null;
    //   }
    // },
    // 获取当前创意的名称
    creativeName(creative, index) {
      let name = creative.type == 'WEB_VIEW' ? '图像或视频' : creative.type == 'COMPOSITE' ? '故事广告' : '精选广告';
      console.log(name);
      return name + ' ' + (index + 1);
    },
    // 点击创意选项卡
    handleClick(tab) {
      console.log(tab);
      //   添加创意
      if (tab.name == '-1') {
        this.current = this.current;

        // console.log(this.value.length);
        // this.$nextTick(() => {
        //   this.current = this.value.length - 1 + '';
        // });
        return false;
      }
    },
    // 编辑选项卡
    handleTabsEdit(index) {
      if (this.value.length == 1) {
        this.$message({
          type: 'warning',
          message: '至少保留一个创意',
        });
        return;
      }
      this.value.splice(index, 1);
      if (this.current >= this.value.length) {
        this.current = `${this.value.length - 1}`;
      }
      this.$emit('input', this.value);
    },
    // 切换广告样式
    selectCreativeType(tab, creative) {
      creative.infos = [];
      //   图像或者视频
      if (tab.name == 'WEB_VIEW') {
      }
      //   故事广告
      if (tab.name == 'COMPOSITE') {
        this.$set(creative.additionalCreativeInfo, 'hasPreview', false);
        creative.additionalCreativeInfo = {
          hasPreview: false,
          headline: '',
          backgroundMediaUrl: '',
          logoMedia: '',
        };
      }

      //   图像或者视频
      if (tab.name == 'COLLECTION') {
        creative.additionalCreativeInfo = {
          collectionMediaUrl: [],
        };
      }
    },
    // 校验图片 格式
    // async selectImg(file, key) {
    //   console.log(file, key);
    //   const imgTypes = key == 'collectionMediaUrl' ? ['image/png', 'image/jpeg'] : ['image/png'];
    //   let isPx = false;
    //   let isType = imgTypes.includes(file.type);
    //   const fileSize = file.size / 1024 / 1024;
    //   let isSize = key == 'collectionMediaUrl' ? fileSize <= 2 : true;
    //   // 格式限制
    //   if (isType) {
    //     if (!file.url) {
    //       file.url = URL.createObjectURL(file);
    //       this.imgUrl = URL.createObjectURL(file);
    //       this.dialogVisible = true;
    //     }
    //     if (!isSize) {
    //       this.$message.error('图片大小不得超过2M');
    //     }
    //     await getImageSize(file.url)
    //       .then((res) => {
    //         const { width, height } = res;
    //         isPx =
    //           key == 'logoMedia'
    //             ? width == 993 && height == 284
    //             : key == 'collectionMediaUrl'
    //             ? width == 260 && height == 260
    //             : width / height == 3 / 5 && width >= 360;
    //         if (!isPx) {
    //           this.$message.error(
    //             key == 'logoMedia'
    //               ? '请选择993*284的图片'
    //               : key == 'collectionMediaUrl'
    //               ? '请选择260*260的图片'
    //               : '请选择宽高比3/5的图片，图片最小尺寸360*600',
    //           );
    //         }
    //       })
    //       .catch((error) => {});
    //   } else {
    //     this.$message.error('文件格式不正确');
    //   }
    //   return isPx && isType && isSize ? Promise.resolve() : Promise.reject();
    // },
    // 设置裁剪参数
    setCutParams(data) {
      this.cutImgParams = { ...this.initCutParams, ...data };
    },
    // async openCut(file, key) {
    //   await getImageSize(file.url)
    //     .then((res) => {
    //       const { width, height } = res;
    //       console.log(width, height);
    //       let obj = this.validateImgSize(width, height, key);
    //       console.log(obj);
    //       isPx = obj.isPx;
    //       isMin = obj.isMin;
    //       if (!isPx) {
    //         if (!isMin) {
    //           this.$message.error(obj.message);
    //         } else {
    //           if (key == 'logoMedia') {
    //             this.setCutParams({
    //               autoCropWidth: 993,
    //               autoCropHeight: 284,
    //               fixedBox: true,
    //               fixedNumber: [993, 284],
    //               limitMinSize: [993, 284],
    //               infoTrue: false,
    //               full: false,
    //               centerBox: false,
    //               enlarge: 1,
    //             });
    //           } else if (key == 'collectionMediaUrl') {
    //             this.setCutParams({
    //               autoCropWidth: 400,
    //               autoCropHeight: 400,
    //               fixedBox: false,
    //               limitMinSize: [260, 260],
    //               fixedNumber: [1, 1],
    //               enlarge: 1,
    //               infoTrue: false,
    //               full: false,
    //               centerBox: false,
    //             });
    //           } else {
    //             this.setCutParams({
    //               autoCropWidth: 600,
    //               autoCropHeight: 1000,
    //               fixedBox: true,
    //               fixedNumber: [3, 5],
    //               enlarge: 1000 / 800,
    //               infoTrue: true,
    //               full: false,
    //             });
    //           }
    //           this.dialogVisible = true;
    //           this.imgUrl = file.url;
    //         }
    //       }
    //     })
    //     .catch((error) => {
    //       this.$message.error('文件读取失败');
    //     });
    // },
    // 校验尺寸
    validateImgSize(width, height, key) {
      let isMin = true, //是否大于最小尺寸,支持裁剪
        isPx = false; // 是否符合尺寸，直接上传
      let message = ''; //提示信息
      isPx =
        key == 'logoMedia'
          ? width == 993 && height == 284
          : key == 'collectionMediaUrl'
          ? width >= 260 && height >= 260 && width / height == 1
          : width / height == 3 / 5 && width >= 360;
      if (!isPx) {
        if (key == 'logoMedia' && (width < 993 || height < 284)) {
          isMin = false;
          message = '图片最小尺寸为 993*284';
        } else if (key == 'collectionMediaUrl' && (width < 260 || height < 260)) {
          isMin = false;
          message = '图片最小尺寸为260*260';
        } else if (key == 'backgroundMediaUrl' && width < 360 && height < 600) {
          isMin = false;
          message = '图片最小尺寸为360*600';
        }
      }
      return { isPx, isMin, message };
    },
    // 选择图片
    async selectImg(file, key) {
      console.log(file, key, 'selectImg');
      const imgTypes = key == 'collectionMediaUrl' ? ['image/png', 'image/jpeg'] : ['image/png'];
      this.fileMsg = {
        name: file.name,
        key: key,
      };
      let isPx = false;
      let isMin = false;
      let isType = imgTypes.includes(file.type);
      const fileSize = file.size / 1024 / 1024;
      let isSize = key == 'collectionMediaUrl' ? fileSize <= 2 : true;
      console.log(isType, file.type, key, 'file.type');

      // 格式限制
      if (isType || (key != 'collectionMediaUrl' && file.type == 'image/jpeg')) {
        if (!file.url) {
          file.url = URL.createObjectURL(file);
        }
        if (!isSize) {
          this.$message.error('图片大小不得超过2M');
        }
        await getImageSize(file.url)
          .then((res) => {
            console.log(res, 'res');

            const { width, height } = res;
            console.log(width, height);
            let obj = this.validateImgSize(width, height, key);
            console.log(obj);
            isPx = obj.isPx;
            isMin = obj.isMin;
            if (!isPx) {
              if (!isMin) {
                this.$message.error(obj.message);
              } else {
                if (key == 'logoMedia') {
                  this.setCutParams({
                    autoCropWidth: 993,
                    autoCropHeight: 284,
                    fixedBox: true,
                    fixedNumber: [993, 284],
                    limitMinSize: [993, 284],
                    infoTrue: false,
                    full: false,
                    centerBox: false,
                    enlarge: 1,
                  });
                } else if (key == 'collectionMediaUrl') {
                  this.setCutParams({
                    autoCropWidth: 400,
                    autoCropHeight: 400,
                    fixedBox: false,
                    limitMinSize: [260, 260],
                    fixedNumber: [1, 1],
                    enlarge: 1,
                    infoTrue: false,
                    full: false,
                    centerBox: false,
                  });
                } else {
                  this.setCutParams({
                    autoCropWidth: 600,
                    autoCropHeight: 1000,
                    fixedBox: true,
                    fixedNumber: [3, 5],
                    enlarge: 1000 / 800,
                    infoTrue: true,
                    full: false,
                  });
                }
                this.dialogVisible = true;
                this.imgUrl = file.url;
              }
            }
          })
          .catch((error) => {
            this.$message.error('文件读取失败');
          });
      } else {
        this.$message.error('文件格式不正确');
      }
      console.log(isPx && isType && isSize && isMin);
      return isPx && isType && isSize && isMin ? Promise.resolve() : Promise.reject();
    },
    // 上传图片
    uploadImgHandle(file, key, creative) {
      uploadImg(file)
        .then((res) => {
          if (key == 'collectionMediaUrl') {
            if (creative.additionalCreativeInfo[key].length >= 4) return;
            creative.additionalCreativeInfo[key].push(res.data.url);
          } else {
            creative.additionalCreativeInfo[key] = res.data.url;
          }
        })
        .catch((err) => {});
    },
    // 接收裁剪过的图片上传
    uploadCutImg(file, key) {
      console.log(file);
      this.uploadImgHandle({ file: file }, key, this.value[this.current]);
    },
  },
};
</script>

<style lang="scss">
.creative .el-tabs--top.el-tabs--card > .el-tabs__header .el-tabs__item:last-child span.el-icon-close {
  display: none !important;
}
.el-tabs--top.el-tabs--card > .el-tabs__header .el-tabs__item div {
  display: inline-block;
}
.creative .el-tabs__new-tab {
  display: none;
}
.uploadSnapchat {
  .el-upload--picture-card {
    width: 150px;
    height: 240px;
  }
}
.el-upload--picture-card {
  width: 100px;
  height: 100px;
}
</style>
